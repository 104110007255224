/**
 * Responsive breakpoints
 */

$breakpoints: (
  xxsm: em(480),
  xsm: em(600),
  sm: em(768),
  smd: em(992),
  md: em(1024),
  mdl: em(1200),
  lg: em(1260),
  xlg: em(1480),
  xxlg: em(1680),
  xxxlg: em(1920),
);

@mixin breakpoints($breakpoint) {
  @media screen and (min-width: map-get($map: $breakpoints, $key: $breakpoint)) {
    @content;
  }
}

@mixin breakpoints_px($px) {
  @media screen and (min-width: $px) {
    @content;
  }
}
