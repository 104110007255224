.more-look {
    $columnGap: 8px;

    &--container {
        height: 100%;
        width: 100%;
        padding: 16px;
        display: flex;
        column-gap: $columnGap;
        overflow-x: auto;

        @include breakpoints(sm) {
            row-gap: 8px;
            flex-wrap: wrap;
        }
    }

    &--image-container {
        $widthCalc: calc(25% - $columnGap);
        min-width: $widthCalc;
        width: $widthCalc;
        height: 100%;
    }

    &--image {
        width: 100%;
        object-fit: contain;
        height: 100%;
        border: 1px solid;
    }
}