.look-image {
    &--container {
        width: 100%;
        height: 100%;
    }

    &--image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}