.button {
    &--mobile {
        @include breakpoints(sm) {
            display: none !important;
        }
    }

    &--desktop {
        display: none !important;

        @include breakpoints(sm) {
            display: inline-block !important;
        }
    }

    &--outlined {
        @extend %button-medium-base;
        border: 1px solid;
        border-radius: 8px;
        padding: rem(8px) rem(12px) rem(8px) rem(8px);
    }

    &--underlined {
        display: inline-block;
        cursor: pointer;
        text-decoration: underline;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &--default {
        background-color: white;
        border: 1px solid;
    }

    &--dark {
        background-color: #000000 !important;
        border: none;

        &>span {
            font-weight: 600;
            color: $lightcolor;
        }
    }

    &--link {
        font-weight: bold;

        &>span {
            text-decoration: underline;
        }
    }

    &--full-width {
        width: 100%;
    }

    &--icon {
        padding: 0 !important;
        margin: 0 !important;
        border: none;
        box-shadow: none;
        background-color: transparent;
    }

}

%button-medium-base {
    border-radius: rem(8px);
    min-height: rem(40px);
}