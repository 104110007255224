.vertical-carousel {
    &--container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100px;
    }

    &--scroll-container {
        height: 100%;
        overflow-y: auto;
        scroll-behavior: smooth;
        width: 100px;
    }

    &--image-container {
        height: calc(33.33% - 7px);
        width: 100%;
        border: 1px solid;
        margin-bottom: 8px;
        
        :last-child {
            margin-bottom: 0;
        }
    }
}