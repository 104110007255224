.select {
    $el: &;

    .ant-select-selector,
    .ant-select-selection-placeholder,
    ant-select-item ant-select-item-option,
    .ant-select-selection-item {
        font-weight: 500 !important;
        font-size: rem(14px);
        line-height: rem(24px);
        color: #1c1c1c;
    }

    .ant-select-arrow {
        color: #1c1c1c;

        svg {
            color: #1c1c1c;
        }
    }

    &--container {
        width: 100%;
        background: $background;
        border-radius: 0;
    }

    &--text {
        font-weight: 500;
        font-size: rem(14px);
        line-height: rem(24px);
    }

    &--option .ant-select-item-option-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &--option-extra {
        padding-left: 8px;
        color: gray;
        display: block;
        overflow: hidden;
    }
}

.ant-layout-content {
    position: relative;
}