.home {
    $bottom-container-height: 48px;

    &--container {
        width: 100%;
        position: relative;
    }

    &--vertical-carousel {
        position: absolute;
        left: 16px;
        top: 15%;
        height: 70%;
        z-index: 1;
    }
    
    &--look-container {
        width: 100%;
        height: calc(100% - $bottom-container-height); // Size of the bottom button container in mobile

        @include breakpoints(sm) {
            height: 100%;
        }
    }

    &--bottom-container {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: $bottom-container-height;
        width: 100%;

        background-color: white;
        padding: 0px 16px;

        @include breakpoints(sm) {
            position: absolute;
            bottom: 0;
            background-color: transparent;
        }
    }
}

.ant-drawer-body {
    padding: 0;
    overflow-y: auto;
}

.ant-drawer-header {
    padding: 16px;
}