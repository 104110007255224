.layout {
    &--container {
        display: flex;
        height: 100%;
        overflow: hidden;
    }

    &--main {
        display: flex;
        flex: 1;
        background-color: lightgrey;
    }

    &--optional {
        display: none;
        flex: 1;
        overflow-y: auto;

        @include breakpoints('sm') {
            display: flex;
        }
    }

}