.card {
    &--container {
        width: 100%;
        /* la largeur est definie par le parent */
        border: none;
        border-radius: 0;
        overflow: hidden;

        &--selected {
            border: 1px solid;
        }

        .ant-card-body {
            padding: rem(10px) rem(10px) rem(20px);
        }

        .ant-card-cover {
            margin-top: 0 !important;
            margin-right: 0 !important;
            margin-left: 0 !important;
        }

        &-favorite {
            .ant-card-body {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
        }
    }

    &--background {
        position: relative;
        width: 100%;
        /* la largeur est definie par le parent */
        height: 0;
        padding-bottom: 125%;
        /* calcul de la hauteur en fonction de la largeur du parent */
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 0;
    }

    &--image {
        width: 100%;
    }

    &--image-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &--image-blur {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        flex-direction: column;
    }

    &--blur {
        filter: blur(3px);
        -webkit-filter: blur(3px);
    }

    &--title {
        margin-top: 0 !important;
        margin-bottom: rem(10px);

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &--text {
        line-height: rem(16px);
        margin-bottom: rem(12px);

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &--without-description {
        .card--text {
            margin-bottom: rem(8px);
        }

        .card--title {
            margin-bottom: rem(12px);
        }
    }

    &--price--promotion {
        font-size: rem(12px) !important;
        margin-top: 0 !important;
    }

    &--price--original {
        text-decoration: line-through;
    }

    &--price--percent {
        margin-left: rem(8px);
    }

    &--favorite {
        position: absolute;
        top: 0;
        right: 0;

        svg {
            height: rem(16px);
            width: rem(16px);
        }
    }

    &--actions {
        width: 100%;
        padding: 0 rem(8px);

        &>button,
        &>.select--container {
            margin-bottom: rem(8px);
        }

        &>button:last-child {
            margin-bottom: 0;
        }

        .action--see-product.action--changing-size {
            display: none;
        }

        @include breakpoints(sm) {
            .action--see-product.action--changing-size {
                display: block;
            }

            .action--see-look {
                display: none;
            }
        }
    }

}