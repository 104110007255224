.title {
    $el: &;

    &--h2 {
        @extend %title-base;
        font-size: rem(18px) !important;
    }

    &--h3 {
        @extend %title-base;
        font-size: rem(14px) !important;
        margin-top: 0 !important;
    }

    &--bottom {
        margin-bottom: rem(10px) !important;
    }
}

%title-base {
    font-weight: bold !important;

    ::first-letter {
        text-transform: uppercase;
    }
}
