.card-cart {
    $el: &;

    &--container {
        display: flex;
        flex-direction: row;
        width: 100%;
        min-height: rem(180px);
        box-sizing: border-box;
        padding: rem(16px);
        border-radius: 0;
        margin-bottom: rem(16px);
        overflow: hidden;

        @include breakpoints(sm) {
            flex-direction: row;
        }
    }

    &--action-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 8px;
        column-gap: 8px;
        width: 100%;

        @include breakpoints(sm) {
            flex-direction: row;
        }
    }

    &--button-container {
        width: 100%;
        flex: 1;
    }

    &--image {
        width: rem(155px);
        max-width: 100%;
        padding: 8px;

        &>img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &--content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 8px;
        padding-left: 0;

        @include breakpoints(mdl) {
            padding-left: 16px;
            align-items: flex-start;
        }
    }

    &--col {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 16px;

        @include breakpoints(sm) {
            margin-bottom: 0;
        }
    }

    &--flex {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &--size {
        flex: 1;
        align-items: center;
        display: flex;
        padding-top: 8px;
    }

    &--title {
        text-align: center;

        @include breakpoints(mdl) {
            text-align: left;
        }
    }

    &--price--promotion {
        font-size: rem(12px) !important;
        margin-top: 0 !important;
    }

    &--price--original {
        text-decoration: line-through;
    }

    &--price--percent {
        margin-left: rem(8px);
    }
}